
import { Component, Vue } from 'vue-property-decorator';
import FullScreenForm from '@/components/FullScreenForm.vue';
import GTextfield from '@/components/gsk-components/GskTextfield.vue';
import GPeoplePicker from '@/components/gsk-components/GskPeoplePicker.vue';
import { BaseUser } from '@/types/users.types';
import GskMultiSelect from '@/components/gsk-components/GskMultiSelect.vue';
import GSelect from '@/components/gsk-components/GskSelect.vue';
import { ProjectDetailsRpaModule } from '@/store/modules/project-details-rpa.module';
import GAnalytics from '@/components/GAnalytics';
import { ClickData } from '@/analytics';
import HelpTooltip from '@/components/HelpTooltip.vue';
import GRadioGroup from '@/components/gsk-components/GskRadioGroup.vue';
import GButton from '@/components/gsk-components/GskButton.vue';
import { RouteNames } from '@/constants';
import BotDetailsForm from '@/components/projects/BotDetailsForm.vue';

const YES = 'Yes';
const NO = 'No';
const isEdit = false;

@Component({
  components: {
    BotDetailsForm,
    FullScreenForm,
    GTextfield,
    GPeoplePicker,
    GskMultiSelect,
    GSelect,
    GAnalytics,
    HelpTooltip,
    GRadioGroup,
    GButton,
  },
})
export default class BotDetails extends Vue {
  get botName(): string {
    return ProjectDetailsRpaModule.botDetails.botName;
  }

  set botName(name: string) {
    ProjectDetailsRpaModule.setBotName(name);
  }

  get isOnCyberArk(): string {
    const { isOnCyberArk } = ProjectDetailsRpaModule.botDetails;
    return isOnCyberArk ? YES : NO;
  }

  set isOnCyberArk(isOn: string) {
    ProjectDetailsRpaModule.setIsOnCyberArk(isOn === YES);
  }

  get sox(): string {
    return ProjectDetailsRpaModule.botDetails.snowDetails.sox;
  }

  set sox(s: string) {
    ProjectDetailsRpaModule.setSox(s);
    ProjectDetailsRpaModule.setExtPropDetails({ key: 'sox', value: s });
  }

  get gxp(): string {
    return ProjectDetailsRpaModule.botDetails.snowDetails.gxp;
  }

  set gxp(g: string) {
    ProjectDetailsRpaModule.setGxp(g);
    ProjectDetailsRpaModule.setExtPropDetails({ key: 'gxp', value: g });
  }

  get pType(): string {
    return ProjectDetailsRpaModule.botDetails.snowDetails.pType;
  }

  set pType(type: string) {
    ProjectDetailsRpaModule.setPType(type);
    ProjectDetailsRpaModule.setExtPropDetails({ key: 'pType', value: type });
  }

  get infoClassification(): string {
    return ProjectDetailsRpaModule.botDetails.snowDetails.infoClassification;
  }

  set infoClassification(info: string) {
    ProjectDetailsRpaModule.setInfoClassification(info);
    ProjectDetailsRpaModule.setExtPropDetails({ key: 'infoClassification', value: info });
  }

  get botDescription(): string {
    return ProjectDetailsRpaModule.botDetails.botDescription;
  }

  set botDescription(desc: string) {
    ProjectDetailsRpaModule.setBotDescription(desc);
  }

  get systemIntegrations(): string[] {
    return ProjectDetailsRpaModule.botDetails.systemIntegrations;
  }

  set systemIntegrations(integrations: string[]) {
    ProjectDetailsRpaModule.setSystemIntegrations(integrations);
    ProjectDetailsRpaModule.setExtPropDetails({ key: 'system-integrations', value: integrations });
  }

  get businessOwner(): BaseUser | undefined {
    return ProjectDetailsRpaModule.botDetails.businessOwner;
  }

  set businessOwner(owner: BaseUser | undefined) {
    if (owner) {
      ProjectDetailsRpaModule.setBusinessOwner(owner);
      ProjectDetailsRpaModule.setExtPropDetails({ key: 'bo', value: owner });
    }
  }

  get businessUnit(): string {
    return ProjectDetailsRpaModule.botDetails.businessUnit;
  }

  set businessUnit(bu: string) {
    ProjectDetailsRpaModule.setBusinessUnit(bu);
  }

  get businessProcess(): string {
    return ProjectDetailsRpaModule.botDetails.businessProcess;
  }

  set businessProcess(process: string) {
    ProjectDetailsRpaModule.setBusinessProcess(process);
    ProjectDetailsRpaModule.setExtPropDetails({ key: 'bp', value: process });
  }

  get riskPartner(): BaseUser | undefined {
    return ProjectDetailsRpaModule.botDetails.riskPartner;
  }

  set riskPartner(partner: BaseUser | undefined) {
    if (partner) {
      ProjectDetailsRpaModule.setRiskPartner(partner);
      ProjectDetailsRpaModule.setExtPropDetails({ key: 'rp', value: partner });
    }
  }

  get deliveredFor(): string {
    return ProjectDetailsRpaModule.botDetails.deliveredFor;
  }

  set deliveredFor(bu: string) {
    ProjectDetailsRpaModule.setDeliveredFor(bu);
    ProjectDetailsRpaModule.setExtPropDetails({ key: 'delivered-for', value: bu });
  }

  get configurationId(): string {
    return ProjectDetailsRpaModule.botDetails.configurationId;
  }

  set configurationId(value: string) {
    ProjectDetailsRpaModule.setConfigurationId(value);
    ProjectDetailsRpaModule.setExtPropDetails({ key: 'config-id', value });
  }

  get configurationIdName(): string {
    return ProjectDetailsRpaModule.botDetails.configurationIdName;
  }

  set configurationIdName(value: string) {
    ProjectDetailsRpaModule.setConfigurationName(value);
    ProjectDetailsRpaModule.setExtPropDetails({ key: 'config-id-name', value });
  }

  get generalDetailsAnalytics(): ClickData {
    return {
      clickTarget: 'Add General Details',
    };
  }

  get projectId() {
    return this.$route.params.projectId;
  }

  get envId() {
    return this.$route.params.envId;
  }

  nextStep(): void {
    ProjectDetailsRpaModule.nextAddBotStep();
  }

  get keywords(): string {
    if (ProjectDetailsRpaModule && ProjectDetailsRpaModule.rpaDraftListing && ProjectDetailsRpaModule.rpaDraftListing.extendedProperties) {
      const { keywords } = ProjectDetailsRpaModule.rpaDraftListing.extendedProperties;
      if (keywords) {
        return keywords.join(' ');
      }
    }
    return '';
  }

  addKeywords(words: string[]): void {
    const keywords = words.flatMap(v => v.split(/,/g)).filter(w => !!w);
    ProjectDetailsRpaModule.updateKeywords({keywords, isEdit});
  }

  get isPrivate(): string {
    return Number(ProjectDetailsRpaModule.rpaDraftListing.isPrivate).toString();
  }

  set isPrivate(p: string) {
    ProjectDetailsRpaModule.updateRpaListing({ isPrivate: Boolean(Number(p)) });
  }

  get isNotComplete(): boolean {
    return (
      !this.botName ||
      !this.botDescription ||
      !this.systemIntegrations.length ||
      !this.businessOwner ||
      !this.businessUnit ||
      !this.deliveredFor
    );
  }

  backToRpaProjectPage(): void {
    this.$router.replace({
      name: RouteNames.ProjectRpa,
      params: {
        id: this.projectId,
        env: this.envId,
        section: 'rpa',
      },
    });
  }
}
