
import { Component, Vue } from 'vue-property-decorator';
import { FileDetail } from '@gsk-tech/gsk-file-upload/gsk-file-upload-base';
import FullScreenForm from '@/components/FullScreenForm.vue';
import GTextfield from '@/components/gsk-components/GskTextfield.vue';
import GSelect from '@/components/gsk-components/GskSelect.vue';
import { EnumsModule } from '@/store/modules/enums.module';
import { AppEnum } from '@/types/enum.types';
import { SelectOption } from '@/components/form/form.types';
import HelpTooltip from '@/components/HelpTooltip.vue';
import { ProjectDetailsRpaModule } from '@/store/modules/project-details-rpa.module';

interface FileEvent extends CustomEvent {
  detail: {
    value: FileDetail[];
  };
}

@Component({
  components: {
    FullScreenForm,
    GTextfield,
    GSelect,
    HelpTooltip,
  },
})
export default class ProjectDetailsRpa extends Vue {
  public processOwnerTooltipText =
    'Process Owner is the entity accountable for the process dimension across functions, delivery locations, and third party providers to ensure an enterprise-wide and truly cross-functional approach to the way services are delivered​​.';

  get projectName(): string {
    return ProjectDetailsRpaModule.newProject.projectName;
  }

  set projectName(name: string) {
    ProjectDetailsRpaModule.setProjectName(name);
  }

  get projectDescription(): string {
    return ProjectDetailsRpaModule.newProject.projectDescription;
  }

  set projectDescription(desc: string) {
    ProjectDetailsRpaModule.setProjectDescription(desc);
  }

  get projectBusinessUnit(): string {
    return ProjectDetailsRpaModule.newProject.projectBusinessUnit;
  }

  set projectBusinessUnit(bu: string) {
    ProjectDetailsRpaModule.setProjectBusinessUnit(bu);
  }

  get businessUnitOptions(): SelectOption<AppEnum>[] {
    return EnumsModule.businessUnitFormOptions;
  }

  get projectBusinessSubUnit(): string {
    return ProjectDetailsRpaModule.newProject.projectBusinessSubUnit;
  }

  set projectBusinessSubUnit(sub: string) {
    ProjectDetailsRpaModule.setProjectBusinessSubUnit(sub);
  }

  nextStep(): void {
    ProjectDetailsRpaModule.nextAddBotStep();
  }

  get isNotComplete(): boolean {
    return !this.projectName || !this.projectDescription || !this.projectBusinessUnit;
  }
}
