
import { Component, Vue, Watch } from 'vue-property-decorator';
import { FileDetail } from '@gsk-tech/gsk-file-upload/gsk-file-upload-base';
import { RawLocation } from 'vue-router';
import { Dictionary } from 'ts-essentials';
import { RouteNames, Roles, RoleNames } from '@/constants';
import VerticalTab from '@/components/Publish/VerticalTab.vue';
import { UITab } from '@/types';
import FullScreenForm from '@/components/FullScreenForm.vue';
import GeneralDetails from '@/Rpa/RpaAssessmentTool/GeneralDetails.vue';
import Grid from '@/components/grid/Grid';
import GridCell from '@/components/grid/GridCell';
import ProjectDetailsRpa from '@/components/projects/ProjectDetailsRpa.vue';
import SelectBot from '@/components/projects/SelectBot.vue';
import BotDetails from '@/components/projects/BotDetails.vue';
import RpaDocumentation from '@/components/projects/RpaDocumentation.vue';
import { ProjectDetailsRpaModule } from '@/store/modules/project-details-rpa.module';
import { AddBotType } from '@/Rpa/constants';
import NavigationList from '@/components/NavigationList.vue';
import * as API from '@/api/projects.api';
import { openSnackbar } from '@/utils/components';
import { ProjectDetailsModule } from '@/store/modules/project-details.module';
import { Environment } from '@/types/enum.types';
import * as Projects from '@/types/projects.types';
import { ListingUser } from '@/types/listings.types';
import { EnumsModule } from '@/store/modules/enums.module';
import cloneDeep from 'lodash/cloneDeep';
import uniqBy from 'lodash/uniqBy';
import { UserModule } from '@/store/modules/user.module';
import { FeatureFlagsModule } from '@/store/modules/feature-flags.module';

interface FileEvent extends CustomEvent {
  detail: {
    value: FileDetail[];
  };
}

@Component({
  components: {
    NavigationList,
    Grid,
    GridCell,
    FullScreenForm,
    VerticalTab,
    GeneralDetails,
    ProjectDetailsRpa,
    SelectBot,
    BotDetails,
    RpaDocumentation,
  },
})
export default class NewProjectRpa extends Vue {
  private modalClosed = false;

  public get closeToProjectRoute(): RawLocation {
    return {
      name: RouteNames.ProjectEnvDetails,
      params: {
        id: this.projectId,
        env: this.envId,
      },
    };
  }

  public get closeToProjectListRoute(): RawLocation {
    return { name: RouteNames.ProjectsList };
  }

  get isNewBotRegistration() {
    const { botRegistrationId } = this.$route.params;
    return botRegistrationId === 'new' || !botRegistrationId;
  }

  closeNewRpa(): void {
    const { type } = this.$route.params;
    if (type === AddBotType.BOT) {
      this.$router.replace(this.closeToRpaProjectRoute);
      ProjectDetailsRpaModule.resetBotDetailsState();
      ProjectDetailsRpaModule.resetSelectBotStatus();
    } else {
      this.$router.replace(this.closeToProjectListRoute);
      ProjectDetailsRpaModule.resetBotDetailsState();
      ProjectDetailsRpaModule.resetSelectBotStatus();
    }
  }

  public get currentComponent(): Vue.VueConstructor | void {
    const { type } = this.$route.params;
    const c = this.activeTab.component || '';
    let components: { [key: string]: Vue.VueConstructor } = {};
    if (type === AddBotType.BOT) {
      if (this.isNewBotRegistration) {
        components = {
          SelectBot,
          BotDetails,
          RpaDocumentation,
        };
      } else {
        components = {
          SelectBot,
        };
      }
    } else if (type === AddBotType.PROJECT) {
      components = {
        ProjectDetailsRpa,
        SelectBot,
        BotDetails,
        RpaDocumentation,
      };
    }
    return components[c];
  }

  public get projectId(): string {
    return this.routeParams.projectId as string;
  }

  public get envId(): string {
    return this.routeParams.envId as string;
  }

  public get section(): string {
    return this.routeParams.section as string;
  }

  public get routeParams(): Dictionary<string | (string | null)[]> {
    const { params } = this.$route;
    return params;
  }

  public get closeToRpaProjectRoute(): RawLocation {
    return {
      name: RouteNames.ProjectRpa,
      params: {
        id: this.projectId,
        env: this.envId,
        section: 'rpa',
      },
    };
  }

  public get flowType() {
    return this.$route.params.type;
  }

  public get flowTitle(): string {
    if (this.isNewRpaProject) {
      return 'Add new project';
    } else {
      return 'Add a bot';
    }
  }

  public get isNewRpaProject() {
    return this.flowType === AddBotType.PROJECT;
  }

  public get tabList(): UITab[] {
    return ProjectDetailsRpaModule.rpaTabList;
  }

  public get activeTab() {
    return ProjectDetailsRpaModule.rpaActiveTab;
  }

  public set activeTab(tab: UITab) {
    ProjectDetailsRpaModule.updateRpaActiveTab(tab);
  }

  async created(): Promise<void> {
    const { type } = this.$route.params;
    let { botSystem } = this.$route.params;
    const { V11, A360 } = EnumsModule.enums.botSystems;
    if (!FeatureFlagsModule.a360Enabled && botSystem === A360.mnemonic) {
      botSystem = V11.mnemonic;
    }
    const { isNewBotRegistration } = this;
    ProjectDetailsRpaModule.setRpaTabList({ type, isNewBotRegistration });
    if (type === AddBotType.BOT && botSystem) {
      ProjectDetailsRpaModule.setProjectBotSystemMnemonic(botSystem);
    }
    await ProjectDetailsRpaModule.getRpaListingTypes();
  }

  @Watch('activeTab.key')
  scrollTop(): void {
    const elem = document.querySelector('#fs-layout-main');
    if (elem) {
      elem.scrollTop = 0;
    }
  }

  get botDetails(): Projects.AddProjectBot {
    const {
      botName,
      botDescription,
      path,
      fileName,
      systemIntegrations,
      businessProcess,
      businessOwner,
      businessUnit,
      riskPartner,
      deliveredFor,
      hasRegulations,
      regulations,
      additionalRegulations,
      configurationId,
      configurationIdName,
      packageId,
      snowDetails,
      isOnCyberArk,
      botSystemMnemonic,
    } = JSON.parse(JSON.stringify(ProjectDetailsRpaModule.botDetails));
    return {
      botName,
      botDescription,
      path,
      fileName,
      systemIntegrations,
      businessProcess,
      businessOwner,
      businessUnit,
      riskPartner,
      deliveredFor,
      hasRegulations,
      regulations,
      additionalRegulations,
      configurationId,
      configurationIdName,
      packageId,
      snowDetails,
      isOnCyberArk,
      botSystemMnemonic,
    };
  }

  get type(): string {
    const { type } = this.$route.params;
    return type;
  }

  async create(): Promise<void> {
    const botDetails = this.botDetails;
    const type = this.type;
    let rpaDraftListing = cloneDeep(ProjectDetailsRpaModule.rpaDraftListing);
    rpaDraftListing.listingUsers = this.buildNewListingOwners(botDetails);
    rpaDraftListing.businessUnitId = this.buId(botDetails.businessUnit);

    if (type === AddBotType.PROJECT) {
      const { projectName, projectDescription, projectBusinessUnit, projectBusinessSubUnit } =
        ProjectDetailsRpaModule.newProject;
      const data = {
        projectName,
        projectDescription,
        businessUnit: projectBusinessUnit,
        businessSubUnit: projectBusinessSubUnit,
        rpa: { ...botDetails, ...rpaDraftListing },
      };

      await API.createProject(data)
        .then(async ({ data: { projectId } }) => {
          openSnackbar.call(this, 'New project created!');
          await this.getProjectDetailsAndNavigateToRpaSection(projectId);
        })
        .catch(e => {
          this.$log('error', e);
          openSnackbar.call(this, 'Server Error: Could not create new project', {
            type: 'error',
          });
        });
    } else {
      if (this.isNewBotRegistration) {
        const botAndListingDetails = {
          ...botDetails,
          ...rpaDraftListing,
        };
        await API.addBotToProjectV2(this.projectId, botAndListingDetails)
          .then(async () => {
            openSnackbar.call(this, 'Bot was successfully added to project');
            await this.getProjectDetailsAndNavigateToRpaSection(Number(this.projectId));
          })
          .catch(e => {
            openSnackbar.call(this, e.message, { type: 'error' });
          });
      } else {
        const { botRegistrationId: regId } = this.$route.params;
        const botRegistrationId = Number(regId);
        await API.addBotDeployment(botRegistrationId, {
          fileName: botDetails.fileName,
          path: botDetails.path,
          systemMnemonic: botDetails.botSystemMnemonic,
        })
        .then(async () => {
          openSnackbar.call(this, 'Bot was successfully added to project');
          await this.getProjectDetailsAndNavigateToRpaSection(Number(this.projectId));
        })
        .catch(e => {
          openSnackbar.call(this, e.message, { type: 'error' });
        })
      }
    }
  }

  protected async getProjectDetailsAndNavigateToRpaSection(projectId: number): Promise<void> {
    await ProjectDetailsModule.getProject(projectId);

    this.$router
      .push({
        name: RouteNames.ProjectRpa,
        params: {
          id: projectId.toString(),
          env: Environment.RPA_DEV,
          section: 'rpa',
        },
      })
      .catch(e => this.$logger.error(e));
  }

  protected buildNewListingOwners(botDetails: Projects.AddProjectBot): ListingUser[] {
    let newOwners: ListingUser[] = [];
    const { email, mudId, firstName, lastName, fullName } = UserModule.user;
    newOwners.push({
      email,
      mudId,
      firstName,
      lastName,
      fullName,
      ...this.newListingOwner,
    });
    newOwners.push({
      ...botDetails.businessOwner,
      ...this.newListingOwner,
    });
    // risk partner is optional field
    if (botDetails.riskPartner) {
      newOwners.push({
        ...botDetails.riskPartner,
        ...this.newListingOwner,
      });
    }
    const { projectUsers } = ProjectDetailsModule.projectDetails;
    if (projectUsers.length) {
      newOwners = projectUsers.map(user => {
        return {
          ...user,
          ...this.newListingOwner,
        };
      });
    }
    return uniqBy(newOwners, 'mudId');
  }

  protected buId(buName: string): number {
    let id = 0;
    EnumsModule.enumsLists.businessUnit.forEach(bu => {
      if (bu.name === buName) {
        id = bu.id;
      }
    });
    return id;
  }

  protected get newListingOwner(): { roleId: number; roleName: string } {
    return {
      roleId: Roles.Owner,
      roleName: RoleNames.Owner,
    };
  }

  destroyed() {
    ProjectDetailsRpaModule.resetBotDetailsState();
  }
}
