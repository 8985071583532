
import { Component, Vue } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import FullScreenForm from '@/components/FullScreenForm.vue';
import GTextfield from '@/components/gsk-components/GskTextfield.vue';
import GSelect from '@/components/gsk-components/GskSelect.vue';
import GRadioGroup from '@/components/gsk-components/GskRadioGroup.vue';
import HelpTooltip from '@/components/HelpTooltip.vue';
import SelectBotTable from '@/Rpa/components/SelectBotTable.vue';
import { UINavigationItem } from '@/types';
import BreadCrumbs from '@/components/BreadCrumbs.vue';
import { ProjectDetailsRpaModule } from '@/store/modules/project-details-rpa.module';
import {
  BotBreadCrumb,
  BotData,
  BotList,
  BotListResponse,
  BotMeta,
  BotTree,
  ActiveBot,
  BotDeployment,
} from '@/types/projects.types';
import { openSnackbar, randId } from '@/utils/components';
import sortBy from 'lodash/sortBy';
import * as API from '@/api/rpa-admin.api';
import { ProjectDetailsModule } from '@/store/modules/project-details.module';
import { EnumsModule } from '@/store/modules/enums.module';
import { FeatureFlagsModule } from '@/store/modules/feature-flags.module';
import { RouteNames } from '@/constants';

interface BotValidErrorPayload {
  botSystemMnemonic: string;
  projectName: string;
  projectRoute: RawLocation;
}

interface BotDeploymentSearchItem extends Partial<BotDeployment>{
  bot: ActiveBot;
}

@Component({
  components: {
    FullScreenForm,
    GTextfield,
    GSelect,
    HelpTooltip,
    SelectBotTable,
    BreadCrumbs,
    GRadioGroup,
  },
})
export default class SelectBot extends Vue {
  public searchTerm = '';

  public loading = false;
  public validBot = false;
  public validBotError: BotValidErrorPayload | null = null;

  get breadCrumbRecord(): BotBreadCrumb[] {
    return ProjectDetailsRpaModule.breadCrumb;
  }

  get devBotList(): BotTree[] {
    return sortBy(
      ProjectDetailsRpaModule.getDevBotList,
      t => t.text.toLowerCase(),
    )
    .map(botTreeItem => {
      const foundDeployment = this.devActiveBotDeploymentsList
      .find((botDeployment) => {
        return botTreeItem._isFile &&
          botDeployment.path === botTreeItem.path &&
          botDeployment.systemMnemonic === this.botSystemMnemonic;
      });
      if (foundDeployment) {
        botTreeItem.disabled = true;
        const { fileName } = foundDeployment;
        const { owner: projectOwner, projectName } = foundDeployment.bot;
        const [owner] = projectOwner;
        botTreeItem.disabledReason = `
          ${fileName} is already in project: ${projectName} 
          owned by ${owner.fullName}. You must be added to 
          this project to have access.
        `;
      }
      return botTreeItem;
    });
  }

  get devBotListFull(): BotListResponse[] {
    return ProjectDetailsRpaModule.getDevBotListFull;
  }

  get currentBotDeployments() {
    const botRegistrationId = Number(this.$route.params.botRegistrationId);
    if (isNaN(botRegistrationId)) {
      return null;
    }
    const botRegistration = ProjectDetailsModule.projectDetails.bots.find(
      (bot) => bot.botRegistrationId === botRegistrationId
    );
    return botRegistration?.systems.map((deployment) => ({
      id: deployment.botDeploymentId,
      title: `${deployment.systemMnemonic} Bot`,
      info: deployment.path,
    }));
  }

  get botSystemOptions(): any[] {
    return Object.values(EnumsModule.enums.botSystems).map((v) => ({
      value: v.mnemonic,
      checked: this.botSystemMnemonic === v.mnemonic,
      disabled: this.loading,
    }));
  }

  protected addBreadCrumb(text: string, children: BotMeta[]): void {
    const newCrumb = {
      text,
      key: `${text}-${randId()}`,
      route: '',
      children,
    };
    ProjectDetailsRpaModule.addToBreadCrumbRecord(newCrumb);
  }

  updateBot(bot: BotTree): void {
    const { text, children } = bot;
    ProjectDetailsRpaModule.updateBot(children);
    this.addBreadCrumb(text, children);
  }

  get chosenBot(): BotData {
    return ProjectDetailsRpaModule.getChosenBot;
  }

  checkSelectedBotError(bot: BotData): void {
    if (!bot) {
      this.validBot = false;
      return;
    }
    const foundBotDeployment = this.devActiveBotDeploymentsList
      .find((botDeployment) => {
        return botDeployment.path === bot.path &&
        botDeployment.systemMnemonic !== this.botSystemMnemonic;
      });
    if (foundBotDeployment) {
      this.validBotError = {
        botSystemMnemonic: String(foundBotDeployment.systemMnemonic),
        projectName: foundBotDeployment.bot.projectName,
        projectRoute: {
          name: RouteNames.ProjectDetails,
          params: {
            id: foundBotDeployment.bot.projectId.toString(),
            projectName: foundBotDeployment.bot.projectName,
          },
        },
      };
    } else {
      this.validBotError = null;
    }
    this.validBot = true;
  }

  selectedBot(bot: BotData): void {
    this.checkSelectedBotError(bot);
    ProjectDetailsRpaModule.setChosenBot(bot);
  }

  async onBotSystemChange(botSystem: string) {
    try {
      const route = this.$route;
      if (route.params.botSystem) {
        this.$router.replace({
          ...route,
          params: {
            ...route.params,
            botSystem,
          },
        } as RawLocation);
      }

      await this.$nextTick();
      if (!this.devBotListFull.length) {
        this.loading = true;
        await this.getBotList(botSystem);
      }
    } catch(e: any) {
      const message = e.respose ? e.response.data.error : e.message;
      openSnackbar.call(this, message, { type: 'error' });
    } finally {
      this.loading = false;
    }
  }

  get devBotListTree(): BotTree[] {
    return ProjectDetailsRpaModule.getDevBotListTree;
  }

  get devActiveBotList(): ActiveBot[] {
    return ProjectDetailsRpaModule.getDevActiveBotList;
  }

  get devActiveBotDeploymentsList(): BotDeploymentSearchItem[] {
    return ProjectDetailsRpaModule.getDevActiveBotList.map((bot: ActiveBot) => {
      return bot.systems.map((system: BotDeployment) => ({
        ...system,
        bot,
      }));
    }).flat();
  }

  get defaultBreadCrumb(): BotBreadCrumb {
    return ProjectDetailsRpaModule.getDefaultBreadCrumb;
  }

  get botSystemMnemonic(): string {
    return ProjectDetailsRpaModule.botDetails.botSystemMnemonic;
  }

  set botSystemMnemonic(botSystem: string) {
    ProjectDetailsRpaModule.setSelectedBotSystem(botSystem);
    ProjectDetailsRpaModule.setProjectBotSystemMnemonic(botSystem);
  }

  get isNewBotRegistration() {
    const { botRegistrationId } = this.$route.params;
    return botRegistrationId === 'new' || !botRegistrationId;
  }

  get botSystemA360Enabled(): boolean {
    return FeatureFlagsModule.a360Enabled;
  }

  onClickBreadcrumb(link: UINavigationItem): void {
    this.searchTerm = '';
    let botList: BotTree[] = [];
    let crumbIndex = -1;
    this.breadCrumbRecord.forEach((crumb, i) => {
      if (crumb.key === link.key) {
        botList = crumb.children;
        crumbIndex = i;
      }
    });
    // update botList
    ProjectDetailsRpaModule.updateDevBotList(botList);
    // update BreadCrumbs
    ProjectDetailsRpaModule.updateBreadCrumbRecordIndex(crumbIndex);
  }

  get breadcrumbs(): UINavigationItem[] {
    if (this.breadCrumbRecord.length > 1) {
      return this.breadCrumbRecord.map(crumb => {
        const { text, key, route } = crumb;
        return {
          text,
          key,
          route,
        };
      });
    } else {
      return [];
    }
  }

  searchBots(): void {
    if (this.searchTerm.length) {
      ProjectDetailsRpaModule.setDefaultBreadCrumb(this.defaultBreadCrumb);
      this.addBreadCrumb(this.searchTerm, []);

      let result: BotTree[] = [];

      this.devBotListFull.forEach((bot: BotListResponse) => {
        function createNewBot(bot: BotList): BotMeta {
          // bot
          return {
            ...bot,
            text: bot.name,
            _isFile: true,
            children: [],
          };
        }

        if (bot.name.toLowerCase().includes(this.searchTerm.toLowerCase())) {
          let newBot = createNewBot(bot);
          result.push(newBot);
        }
      });

      ProjectDetailsRpaModule.updateDevBotList(result);
    } else {
      // No search term, load full tree
      ProjectDetailsRpaModule.updateDevBotList(this.devBotListTree);
      // Reset breadcrumb
      ProjectDetailsRpaModule.setDefaultBreadCrumb(this.defaultBreadCrumb);
    }
  }

  get hasNoValidBotSelected(): boolean {
    return !this.chosenBot.name || !this.validBot;
  }

  nextStep(): void {
    if (this.isNewBotRegistration) {
      ProjectDetailsRpaModule.nextAddBotStep();
    } else {
      this.$emit('submit');
    }
  }

  async getBotList(botSystemMnemonic: string) {
    const botList = await API.getBotList(botSystemMnemonic);
    ProjectDetailsRpaModule.setSelectBotStatus({
      botList,
      botSystemMnemonic,
    });
  }

  async created(): Promise<void> {
    try {
      this.loading = true;
      const { botSystemMnemonic } = ProjectDetailsRpaModule.newProject;

      const activeBotList = await API.getActiveBotList();
      ProjectDetailsRpaModule.setActiveBotStatus(activeBotList);

      if (!this.devBotListFull.length && !this.chosenBot.path) {
        await this.getBotList(botSystemMnemonic);
      }

      if (this.chosenBot) {
        this.checkSelectedBotError(this.chosenBot);
      }

    } catch(e: any) {
      const message = e.response ? e.response.data.error : e.message;
      openSnackbar.call(this, message, { type: 'error' });
    } finally {
      this.loading = false;
    }
  }
}
