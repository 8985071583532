
import { Component, Prop, Vue } from 'vue-property-decorator';
import HelpTooltip from '@/components/HelpTooltip.vue';
import { BotData, BotMeta, BotTree } from '@/types/projects.types';

@Component({
  components: {
    HelpTooltip,
  },
})
export default class SelectBotTable extends Vue {
  @Prop(Array) readonly botList!: BotTree[];
  @Prop(Object) readonly chosenBot!: BotData;

  onClick(bot: BotTree): void {
    if (bot.disabled) {
      return;
    }
    if (bot._isFile) {
      this.$emit('selected-bot', bot);
    } else {
      this.$emit('update', bot);
    }
  }

  selectIcon(isFile: boolean): string {
    return isFile ? 'document_code' : 'folder';
  }

  selectClass(bot: BotMeta): any {
    return {
      'file-selected': bot._isFile && this.chosenBot.path === bot.path,
      'file': bot._isFile,
      'folder': !bot._isFile,
      'file-disabled': bot.disabled,
    };
  }

  dateLastModified(bot: BotMeta) {
    return bot._isFile ? this.formatDate(bot.clientLastModifiedDate) : '—';
  }

  dateUploaded(bot: BotMeta) {
    return bot._isFile ? this.formatDate(bot.uploadedDate) : '—';
  }

  formatDate(d: string): string {
    const date = new Date(d);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    })}`;
  }
}
